import { Box, Button, Container, IconButton, Grid, TextField, Typography, Stack, InputAdornment, RadioGroup, FormControlLabel, Radio, FormHelperText } from '@mui/material'

import React, { useRef, useState } from 'react'
import { Field, Form, FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';
import CloseIcon from "@mui/icons-material/Close";
import axios from 'axios';

import logo from "../src/logo_treasure_latex_factory.jpg"
import { format } from 'date-fns';
import { useSnackbar } from 'notistack';
import AlertDialog from './AlertDialog';
const baseURL = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_DEV_API_URL : process.env.REACT_APP_PRD_API_URL;

// ----------------------------------------------------------------------
function fDateTo(date, f) {
    return format(new Date(date), f);
}

export default function Home() {
    const { enqueueSnackbar } = useSnackbar();
    const [open, setOpen] = useState(false);
    const [severity, setSeverity] = useState("success");
    // const [severity, setSeverity] = useState("error");
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const [selectedFile, setSelectedFile] = useState(null);
    const fileInputRef = useRef(null);

    const formSchema = yup.object().shape({
        datebook: yup.string().required('required'),
        timebook: yup.string().required('required'),
        thaiguidecode: yup.string().required('required'),
        thaifullname: yup.string().required('required'),
        v_c_fullname: yup.string().required('required'),
        tourcompany: yup.string().required('required'),
        groupcode: yup.string().required('required'),
        selectedOptionnationality: yup.string().required('required'),
        nationality: yup.string().when('selectedOptionnationality', {
            is: (value) => value === 'OTHER',
            then: (schema) => schema.required('required'),
        }),
        adults: yup.string().required('required,If none, enter 0.').matches(/^[0-9]+$/, 'format only number'),
        tourleaders: yup.string().required('required,If none, enter 0.').matches(/^[0-9]+$/, 'format only number'),
        child: yup.string().required('required,If none, enter 0.').matches(/^[0-9]+$/, 'format only number'),
        licenseplate: yup.string().required('required').matches(/^[a-zA-Z0-9ก-ฮ]{2,3}-\d{4}$/, 'format xx-xxxx or xxx-xxxx'),
        vehicletype: yup.string().required('required'),
        vehicletypename: yup.string().when('vehicletype', {
            is: (value) => (value === 'รถบริษัทอื่น其他公司巴士 / รถเช่า 出租巴士' || value === '面包车 รถตู้ 汽车รถยนต์'),
            then: (schema) => schema.required('required'),
        }),
        contactnumber: yup.string().required('required'),
    });
    const formik = useFormik({
        initialValues: {
            datebook: "",
            timebook: "",
            thaiguidecode: "",
            thaifullname: "",
            v_c_fullname: "",
            tourcompany: "",
            groupcode: "",
            nationality: "",
            adults: "",
            tourleaders: "",
            child: "",
            licenseplate: "",
            vehicletype: "",
            contactnumber: "",
            selectedOptionnationality: "",
            vehicletypename: ""
        },
        validationSchema: formSchema,
        onSubmit: (values, { setSubmitting }) => {
            const databody = {
                ...values,
                datebook: fDateTo(values.datebook, "yyyy-MM-dd"),
                nationality: values.selectedOptionnationality === "OTHER" ? values.nationality : values.selectedOptionnationality, vehicletypename: (values.vehicletype === 'รถบริษัทอื่น其他公司巴士 / รถเช่า 出租巴士' || values.vehicletype === '面包车 รถตู้ 汽车รถยนต์') ? values.vehicletypename : ""
            }
            delete databody['selectedOptionnationality'];
            const formData = new FormData();
            formData.append('file', selectedFile);
            formData.append('body', JSON.stringify(databody));

            // axios.post('http://localhost:4000/form/api/upload', formData, {
            axios.post(`${baseURL}/form/api/upload`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
                .then((res) => {
                    handleFormReset()
                    setSubmitting(false);
                    // handleSnackbar({ message: "Success", variant: "success" })
                    setSeverity("success")
                    handleClickOpen()
                })
                .catch((error) => {
                    // console.error('Error uploading file:', error);
                    // handleSnackbar({ message: "Error,please try again later", variant: "error" })
                    setSeverity("error")
                    handleClickOpen()
                    setSubmitting(false);
                })
        },
    });


    const handleFileChange = (event) => {
        if (event.target.files[0] !== undefined) {
            const file = event.target.files[0];
            setSelectedFile(file);
        }
    };
    const handleTriggerUpload = (event) => {
        event.preventDefault();
        fileInputRef.current.click()
    };
    const resetForm = () => {
        formik.resetForm()
        setSelectedFile(null)
        fileInputRef.current.value = ''
    }
    const handleFilereset = () => {
        setSelectedFile(null);
        fileInputRef.current.value = ''
    };

    const handleFormReset = () => {
        handleFilereset()
        formik.resetForm()
    }

    const handleSnackbar = ({ variant, message }) => {
        enqueueSnackbar(message, { variant, autoHideDuration: 1500, anchorOrigin: { horizontal: 'right', vertical: 'bottom' } });
    };

    return (
        <Container maxWidth="sm" sx={{ backgroundColor: "white" }}>
            <Box>
                <img src={logo} width="100%" style={{ aspectRatio: "auto 16/9", borderRadius: "5px" }} alt='logo' />
            </Box>
            <FormikProvider value={formik}>
                <Form autoComplete="off" noValidate onSubmit={formik.handleSubmit}>
                    <Grid
                        container
                        spacing={2}
                        direction="row"
                        alignItems="center"
                        justifyContent="center"
                        sx={{
                            color: "#906C4E"
                        }}
                    >
                        <Grid item xs={12} sx={{
                            textAlign: "center",
                            margin: "10px"
                        }} >
                            <Typography variant="h5" >ลงทะเบียนเข้าร้านยางพารา Treasure Latex Factory</Typography>
                            <Typography variant="h6" >注册進乳胶店的政策 Treasure Latex Factory</Typography>
                        </Grid>
                        <Grid item xs={12} >
                            วันที่ต้องการจองเข้าร้าน / 預約進乳胶店日期
                        </Grid>
                        <Grid item xs={12} >
                            <TextField
                                onChange={formik.handleChange}
                                error={formik.touched.datebook && Boolean(formik.errors.datebook)}
                                helperText={formik.touched.datebook && formik.errors.datebook}
                                name="datebook"
                                type="date"
                                value={formik.values.datebook}
                                id="outlined-basic-datebook"
                                label=""
                                variant="outlined"
                                size="small"
                                fullWidth
                            // inputProps={{ min: new Date().toISOString().slice(0, 10) }}
                            />
                        </Grid>

                        <Grid item xs={12} >
                            เวลาที่เข้าร้าน / 進乳胶店時間
                        </Grid>
                        <Grid item xs={12} >
                            <TextField
                                onChange={formik.handleChange}
                                error={formik.touched.timebook && Boolean(formik.errors.timebook)}
                                helperText={formik.touched.timebook && formik.errors.timebook}
                                name="timebook"
                                value={formik.values.timebook}
                                type="time"
                                id="outlined-basic-timebook"
                                label=""
                                variant="outlined"
                                size="small"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} >
                            รหัสบัตรไกด์ไทย / 泰国导游証号码
                        </Grid>
                        <Grid item xs={12} >
                            <TextField
                                onChange={formik.handleChange}
                                error={formik.touched.thaiguidecode && Boolean(formik.errors.thaiguidecode)}
                                helperText={formik.touched.thaiguidecode && formik.errors.thaiguidecode}
                                name="thaiguidecode"
                                value={formik.values.thaiguidecode}
                                type="text"
                                id="outlined-basic-thaiguidecode"
                                label=""
                                variant="outlined"
                                size="small"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} >
                            ชื่อ-นามสกุลไกด์ไทย / 泰国导游的姓名
                        </Grid>
                        <Grid item xs={12} >
                            <TextField
                                onChange={formik.handleChange}
                                error={formik.touched.thaifullname && Boolean(formik.errors.thaifullname)}
                                helperText={formik.touched.thaifullname && formik.errors.thaifullname}
                                name="thaifullname"
                                value={formik.values.thaifullname}
                                id="outlined-basic-thaifullname"
                                label=""
                                variant="outlined"
                                size="small"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} >
                            ชื่อไกด์เวียดนาม / ไกด์จีน /越南导游/中国导游姓名
                        </Grid>
                        <Grid item xs={12} >
                            <TextField
                                onChange={formik.handleChange}
                                error={formik.touched.v_c_fullname && Boolean(formik.errors.v_c_fullname)}
                                helperText={formik.touched.v_c_fullname && formik.errors.v_c_fullname}
                                name="v_c_fullname"
                                value={formik.values.v_c_fullname}
                                id="outlined-basic-v_c_fullname"
                                label=""
                                variant="outlined"
                                size="small"
                                fullWidth
                            />

                        </Grid>
                        <Grid item xs={12} >
                            ชื่อบริษัททัวร์ / 旅游公司名称
                        </Grid>
                        <Grid item xs={12} >
                            <TextField
                                onChange={formik.handleChange}
                                error={formik.touched.tourcompany && Boolean(formik.errors.tourcompany)}
                                helperText={formik.touched.tourcompany && formik.errors.tourcompany}
                                name="tourcompany"
                                value={formik.values.tourcompany}
                                id="outlined-basic-tourcompany"
                                label=""
                                variant="outlined"
                                size="small"
                                fullWidth
                            />

                        </Grid>

                        <Grid item xs={12} >
                            กรุ๊ปโค๊ด / 团号
                        </Grid>
                        <Grid item xs={12} >
                            <TextField
                                onChange={formik.handleChange}
                                error={formik.touched.groupcode && Boolean(formik.errors.groupcode)}
                                helperText={formik.touched.groupcode && formik.errors.groupcode}
                                name="groupcode"
                                value={formik.values.groupcode}
                                id="outlined-basic-groupcode"
                                label=""
                                variant="outlined"
                                size="small"
                                fullWidth
                            />

                        </Grid>

                        <Grid item xs={12} >
                            สัญชาติลูกค้า / 客户的国籍
                        </Grid>
                        <Grid item xs={12} >
                            <Field name="selectedOptionnationality">
                                {({ field, meta }) => (
                                    <>
                                        <RadioGroup
                                            {...field}
                                            aria-labelledby="nationality-radio-buttons-group-label"
                                            name="selectedOptionnationality"
                                            value={formik.values.selectedOptionnationality}
                                            onChange={formik.handleChange}
                                        >
                                            <FormControlLabel value="CHINA" control={<Radio />} label="CHINA" />
                                            <FormControlLabel value="VIETNAM" control={<Radio />} label="VIETNAM" />
                                            <FormControlLabel value="RUSSIA" control={<Radio />} label="RUSSIA" />
                                            <FormControlLabel value="OTHER" control={<Radio />} label="OTHER" />
                                        </RadioGroup>
                                        {
                                            meta.error && Boolean(meta.touched) && (
                                                <FormHelperText error>{meta.error}</FormHelperText>
                                            )
                                        }
                                    </>
                                )}
                            </Field>
                            {formik.values.selectedOptionnationality === 'OTHER' && (
                                <TextField
                                    onChange={formik.handleChange}
                                    error={formik.touched.nationality && Boolean(formik.errors.nationality)}
                                    helperText={formik.touched.nationality && formik.errors.nationality}
                                    name="nationality"
                                    value={formik.values.nationality}
                                    id="outlined-basic-nationality"
                                    label=""
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                />
                            )}
                        </Grid>

                        <Grid item xs={12} >
                            จำนวนผู้ใหญ่ / 成人人数
                        </Grid>
                        <Grid item xs={12} >
                            <TextField
                                onChange={formik.handleChange}
                                error={formik.touched.adults && Boolean(formik.errors.adults)}
                                helperText={formik.touched.adults && formik.errors.adults}
                                name="adults"
                                value={formik.values.adults}
                                id="outlined-basic-adults"
                                label=""
                                variant="outlined"
                                size="small"
                                fullWidth
                                inputProps={{ inputMode: "numeric" }}
                            />

                        </Grid>
                        <Grid item xs={12} >
                            จำนวนหัวหน้าทัวร์ / 领队人数
                        </Grid>
                        <Grid item xs={12} >
                            <TextField
                                onChange={formik.handleChange}
                                error={formik.touched.tourleaders && Boolean(formik.errors.tourleaders)}
                                helperText={formik.touched.tourleaders && formik.errors.tourleaders}
                                name="tourleaders"
                                value={formik.values.tourleaders}
                                id="outlined-basic-tourleaders"
                                label=""
                                variant="outlined"
                                size="small"
                                fullWidth
                                inputProps={{ inputMode: "numeric" }}
                            />

                        </Grid>

                        <Grid item xs={12} >
                            จำนวนลูกค้าเด็ก / 儿童人数
                        </Grid>
                        <Grid item xs={12} >
                            <TextField
                                onChange={formik.handleChange}
                                error={formik.touched.child && Boolean(formik.errors.child)}
                                helperText={formik.touched.child && formik.errors.child}
                                name="child"
                                value={formik.values.child}
                                id="outlined-basic-child"
                                label="" variant="outlined"
                                size="small"
                                fullWidth
                                inputProps={{ inputMode: "numeric" }}
                            />
                        </Grid>
                        <Grid item xs={12} >
                            ทะเบียนรถ / 車牌号
                        </Grid>
                        <Grid item xs={12} >
                            <TextField
                                onChange={formik.handleChange}
                                error={formik.touched.licenseplate && Boolean(formik.errors.licenseplate)}
                                helperText={formik.touched.licenseplate && formik.errors.licenseplate}
                                name="licenseplate"
                                value={formik.values.licenseplate}
                                id="outlined-basic-licenseplate"
                                label="" variant="outlined"
                                size="small"
                                fullWidth />
                        </Grid>
                        <Grid item xs={12} >
                            ประเภทรถ / 车型
                        </Grid>
                        <Grid item xs={12} >
                            <Field name="vehicletype">
                                {({ field, meta }) => (
                                    <>
                                        <RadioGroup
                                            {...field}
                                            aria-labelledby="vehicletype-radio-buttons-group-label"
                                            name="vehicletype"
                                            value={formik.values.vehicletype}
                                            onChange={formik.handleChange}
                                        >
                                            <FormControlLabel value="巴士  Siamgems / First Tran Sport" control={<Radio />} label="巴士  Siamgems / First Tran Sport" />
                                            <FormControlLabel value="巴士 OA (คิงวิน,ไทยลักซ์,เอ็มบัส)" control={<Radio />} label="巴士 OA (คิงวิน,ไทยลักซ์,เอ็มบัส)" />
                                            <FormControlLabel value="巴士 Wang Thonglang" control={<Radio />} label="巴士 Wang Thonglang" />
                                            <FormControlLabel value="巴士 Elephat Gogo" control={<Radio />} label="巴士 Elephat Gogo" />
                                            <FormControlLabel value="รถบริษัทอื่น其他公司巴士 / รถเช่า 出租巴士" control={<Radio />} label="รถบริษัทอื่น其他公司巴士 / รถเช่า 出租巴士" />

                                            {(formik.values.vehicletype === 'รถบริษัทอื่น其他公司巴士 / รถเช่า 出租巴士' || formik.values.vehicletype === 'รถบริษัทอื่น其他公司巴士 / รถเช่า 出租巴士') && (
                                                <TextField
                                                    onChange={formik.handleChange}
                                                    error={formik.touched.vehicletypename && Boolean(formik.errors.vehicletypename)}
                                                    helperText={formik.touched.vehicletypename && formik.errors.vehicletypename}
                                                    name="vehicletypename"
                                                    value={formik.values.vehicletypename}
                                                    id="outlined-basic-vehicletypename"
                                                    label=""
                                                    variant="outlined"
                                                    size="small"
                                                    fullWidth
                                                />
                                            )}
                                            <FormControlLabel value="面包车 รถตู้ 汽车รถยนต์" control={<Radio />} label="面包车 รถตู้ 汽车รถยนต์" />
                                            {(formik.values.vehicletype === '面包车 รถตู้ 汽车รถยนต์' || formik.values.vehicletype === '面包车 รถตู้ 汽车รถยนต์') && (
                                                <TextField
                                                    onChange={formik.handleChange}
                                                    error={formik.touched.vehicletypename && Boolean(formik.errors.vehicletypename)}
                                                    helperText={formik.touched.vehicletypename && formik.errors.vehicletypename}
                                                    name="vehicletypename"
                                                    value={formik.values.vehicletypename}
                                                    id="outlined-basic-vehicletypename"
                                                    label=""
                                                    variant="outlined"
                                                    size="small"
                                                    fullWidth
                                                />
                                            )}
                                        </RadioGroup>
                                        {
                                            meta.error && Boolean(meta.touched) && (
                                                <FormHelperText error>{meta.error}</FormHelperText>
                                            )
                                        }
                                    </>
                                )}
                            </Field>
                        </Grid>

                        <Grid item xs={12} >
                            เบอร์โทรศัพท์ติดต่อ / 导游联系电话
                        </Grid>
                        <Grid item xs={12} >
                            <TextField
                                onChange={formik.handleChange}
                                error={formik.touched.contactnumber && Boolean(formik.errors.contactnumber)}
                                helperText={formik.touched.contactnumber && formik.errors.contactnumber}
                                name="contactnumber"
                                value={formik.values.contactnumber}
                                id="outlined-basic-contactnumber"
                                label="" variant="outlined"
                                size="small"
                                fullWidth
                                inputProps={{ inputMode: "tel" }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Box>
                                <Typography fontStyle="italic" >แนบไฟล์ NAME LIST</Typography>
                            </Box>
                            <Box>
                                <input
                                    type="file"
                                    accept='image/*, application/pdf, .csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
                                    hidden
                                    onChange={handleFileChange}
                                    ref={fileInputRef}
                                />
                            </Box>
                            <Box>
                                <Stack spacing={1} direction="row" justifyContent="space-between">
                                    <TextField InputProps={{
                                        readOnly: true,
                                        endAdornment: (
                                            selectedFile !== null && (
                                                <InputAdornment position="end" >
                                                    <IconButton size="small" onClick={handleFilereset}><CloseIcon fontSize="inherit" /></IconButton>
                                                </InputAdornment>
                                            )
                                        )
                                    }} size="small" fullWidth type="text" placeholder='choose file' value={selectedFile !== null ? selectedFile?.name : ""} />
                                    <Button size="small" onClick={handleTriggerUpload} variant="outlined" color="success">Choose</Button>
                                </Stack>
                            </Box>
                        </Grid>
                    </Grid>
                    <Box sx={{ marginY: "20px" }}>
                        <Stack spacing={1} justifyContent="space-between" direction="row" >
                            <Box>
                                <Button
                                    variant="contained"
                                    type="submit"
                                    disabled={formik.isSubmitting}
                                >
                                    ส่ง
                                </Button>
                            </Box>
                            <Box>
                                <Button
                                    variant="outlined"
                                    color="secondary"
                                    onClick={resetForm}
                                >
                                    ล้างแบบฟอร์ม
                                </Button>
                            </Box>
                        </Stack>
                    </Box>
                </Form>
            </FormikProvider >
            <AlertDialog open={open} handleClose={handleClose} severity={severity} message={severity === "success" ? "Information saved successfully." : "Unable to save information"} />
        </Container>
    )
}
