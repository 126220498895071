import { Avatar, Box, Divider, Button, Container, Grid, MenuItem, Popover, Stack, Table, TableBody, TableCell, TableHead, TableRow, TextField, AppBar, Toolbar, Checkbox, Typography } from '@mui/material'
import axios from 'axios';
import { Form, FormikProvider, useFormik } from 'formik';
import React, { Fragment, useState } from 'react'
import * as yup from 'yup';
import { format } from 'date-fns';
import { useSnackbar } from 'notistack';
import { useAuth } from './AuthContext';
import { useNavigate } from 'react-router-dom';
import ExcelExport from './ExcelExport';
import _ from 'lodash';
const baseURL = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_DEV_API_URL : process.env.REACT_APP_PRD_API_URL;

// ----------------------------------------------------------------------
function fDateTo(date, f) {
    return format(new Date(date), f);
}

export default function Admin() {

    const { logout, authendata } = useAuth();
    const { contype, email, firstname, lastname } = authendata
    const navigate = useNavigate()

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const handleSnackWithAction = ({ message, onHandle }) => {
        enqueueSnackbar(message, {
            anchorOrigin: {
                horizontal: "center",
                vertical: 'bottom'
            },
            variant: 'default',
            action: (key) => (
                <Fragment>
                    <Button
                        size='small'
                        color="error"
                        onClick={() => {
                            closeSnackbar(key)
                            onHandle()
                        }}
                    >
                        ใช่
                    </Button>
                    <Button size='small' color="success" onClick={() => closeSnackbar(key)}>
                        ปิด
                    </Button>
                </Fragment>
            )
        });
    };




    const [anchorEl, setAnchorEl] = useState(null);

    const [rows, setRows] = useState([])
    const [rowsDownload, setRowsDownload] = useState([])

    const formSchema = yup.object().shape({
        datestart: yup.string().required('required'),
        dateend: yup.string().required('required')
    });
    const formik = useFormik({
        initialValues: {
            datestart: "",
            dateend: ""
        },
        validationSchema: formSchema,
        onSubmit: (values) => {
            const start = fDateTo(values.datestart, "yyyy-MM-dd")
            const end = fDateTo(values.dateend, "yyyy-MM-dd")
            axios.get(`${baseURL}/view/report/${start}/${end}`)
                .then((res) => {
                    setRows(res.data)
                    if (res.data.length === 0) {
                        handleSnackbar({ message: "Not found", variant: "error" })
                    }
                })
                .catch((error) => {
                    setRows([])
                    handleSnackbar({ message: "error", variant: "error" })
                })
        },
    });
    const handleSnackbar = ({ variant, message }) => {
        enqueueSnackbar(message, { variant, autoHideDuration: 1500, anchorOrigin: { horizontal: 'right', vertical: 'bottom' } });
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);


    const onClickConfirm = ({ id, status }) => {
        const body = { id, status }
        axios.put(`${baseURL}/list/api/update`, body)
            .then((res) => {
                handleSnackbar({ message: "success!", variant: "success" })
                formik.submitForm()
            })
            .catch((error) => {
                handleSnackbar({ message: "error", variant: "error" })
            })
    }
    const onDelete = ({ id, filename }) => {
        const body = { id, filename }
        axios.delete(`${baseURL}/list/api/remove`, { data: body })
            .then((res) => {
                handleSnackbar({ message: "success!", variant: "success" })
                formik.submitForm()
            })
            .catch((error) => {
                handleSnackbar({ message: "error", variant: "error" })
            })
    }

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            setRowsDownload(rows);
        } else {
            setRowsDownload([]);
        }
    };
    const handleCheck = (row) => {
        const selected = isSelected(row.id);
        if (selected) {
            const newSelectedRows = rowsDownload.filter((rowDownload) => rowDownload.id !== row.id);
            setRowsDownload(newSelectedRows);
        } else {
            const newSelectedRows = rowsDownload.concat(row);
            setRowsDownload(newSelectedRows);
        }
    }
    const isSelected = (id) => {
        const rowSelected = rowsDownload.filter((v) => v.id === id)
        return rowSelected.length > 0
    }

    return (
        <Container maxWidth={false} sx={{ backgroundColor: "white" }}>
            <Box sx={{ flexGrow: 1 }}>
                <AppBar position="sticky">
                    <Toolbar sx={{ marginLeft: 'auto' }}>
                        <Avatar sx={{ cursor: "pointer" }} onClick={handleClick} />
                    </Toolbar>
                </AppBar>
                <Popover
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                >
                    <Box sx={{ padding: "16px" }}>
                        <Typography variant="subtitle1">Name : {`${firstname} ${lastname}`}</Typography>
                        <Typography variant="subtitle1">Email : {email}</Typography>
                    </Box>
                    <Divider />
                    <MenuItem onClick={() => {
                        handleClose()
                        logout()
                        navigate('/login')
                    }}>Logout</MenuItem>
                </Popover>
            </Box>
            <Box padding={2}>
                <FormikProvider value={formik}>
                    <Form autoComplete="off" noValidate onSubmit={formik.handleSubmit}>
                        <Grid
                            container
                            spacing={1}
                            direction="row"
                            alignItems="center"
                            justifyContent="center"
                            sx={{
                                color: "#906C4E"
                            }}
                        >
                            <Grid item xs={4} >
                                <TextField
                                    label="จากวันที่"
                                    onChange={formik.handleChange}
                                    error={formik.touched.datestart && Boolean(formik.errors.datestart)}
                                    // helperText={formik.touched.datestart && formik.errors.datestart}
                                    name="datestart"
                                    type="date"
                                    value={formik.values.datestart}
                                    id="outlined-basic-1"
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>
                            <Grid item xs={4} >
                                <TextField
                                    label="ถึงวันที่"
                                    onChange={formik.handleChange}
                                    error={formik.touched.dateend && Boolean(formik.errors.dateend)}
                                    // helperText={formik.touched.dateend && formik.errors.dateend}
                                    name="dateend"
                                    type="date"
                                    value={formik.values.dateend}
                                    id="outlined-basic-2"
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>
                            <Grid item xs={4} >
                                <Box>
                                    <Stack spacing={1} direction="row">
                                        <Button
                                            variant="contained"
                                            type="submit"
                                            fullWidth
                                            sx={{ height: "100%" }}
                                        >
                                            ค้นหา
                                        </Button>
                                        {
                                            rows.length > 0 && (
                                                <ExcelExport disabled={rowsDownload.length === 0} data={
                                                    _.orderBy(rowsDownload, ["datebook", "timebook"], ["asc", "asc"]).map(v => {
                                                        return {
                                                            "วันที่จองเข้าร้าน": fDateTo(v.datebook, "dd/MM/yyyy"),
                                                            "เวลาที่เข้าร้าน": v.timebook,
                                                            "รหัสบัตรไกด์ไทย": v.thaiguidecode,
                                                            "ชื่อ-นามสกุลไกด์ไทย": v.thaifullname,
                                                            "ชื่อไกด์เวียดนาม/จีน": v.v_c_fullname,
                                                            "ชื่อบริษัททัวร์": v.tourcompany,
                                                            "กรุ๊ปโค๊ด": v.groupcode,
                                                            "สัญชาติลูกค้า": v.nationality,
                                                            "จำนวนผู้ใหญ่": v.adults,
                                                            "จำนวนหัวหน้าทัวร์": v.tourleaders,
                                                            "จำนวนลูกค้าเด็ก": v.child,
                                                            "ทะเบียนรถ": v.licenseplate,
                                                            "ประเภทรถ": v.vehicletype,
                                                            "ชื่อประเภทรถ": v.vehicletypename,
                                                            "เบอร์โทรศัพท์ติดต่อ": v.contactnumber,
                                                            "ไฟล์": v.file_id !== "" ? `${baseURL}/view/${v.file_id}` : ``,
                                                            "สถานะ": v.status === "confirm" ? "ยืนยัน" : "ไม่ยืนยัน"
                                                        }
                                                    })
                                                } fileName={`booking-${fDateTo(formik.values.datestart, "dd-MM-yyyy")}-${fDateTo(formik.values.dateend, "dd-MM-yyyy")}`} />
                                            )
                                        }
                                    </Stack>
                                </Box>
                            </Grid>
                        </Grid>
                    </Form>
                </FormikProvider>
                <Box sx={{ overflow: "auto" }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ whiteSpace: "nowrap" }} padding="checkbox">
                                    <Checkbox
                                        indeterminate={rowsDownload.length > 0 && rowsDownload.length < rows.length}
                                        checked={rows.length > 0 && rowsDownload.length === rows.length}
                                        onChange={handleSelectAllClick}
                                    />
                                </TableCell>
                                <TableCell sx={{ whiteSpace: "nowrap" }}>วันที่จองเข้าร้าน</TableCell>
                                <TableCell sx={{ whiteSpace: "nowrap" }}>เวลาที่เข้าร้าน</TableCell>
                                <TableCell sx={{ whiteSpace: "nowrap" }}>รหัสบัตรไกด์ไทย</TableCell>
                                <TableCell sx={{ whiteSpace: "nowrap" }}>ชื่อ-นามสกุลไกด์ไทย</TableCell>
                                <TableCell sx={{ whiteSpace: "nowrap" }}>ชื่อไกด์เวียดนาม/จีน</TableCell>
                                <TableCell sx={{ whiteSpace: "nowrap" }}>ชื่อบริษัททัวร์</TableCell>
                                <TableCell sx={{ whiteSpace: "nowrap" }}>กรุ๊ปโค๊ด</TableCell>
                                <TableCell sx={{ whiteSpace: "nowrap" }}>สัญชาติลูกค้า</TableCell>
                                {/* <TableCell sx={{ whiteSpace: "nowrap" }}>{`จำนวน (ผู้ใหญ่,หัวหน้าทัวร์,เด็ก)`}</TableCell> */}
                                <TableCell sx={{ whiteSpace: "nowrap" }}>จำนวนผู้ใหญ่</TableCell>
                                <TableCell sx={{ whiteSpace: "nowrap" }}>จำนวนหัวหน้าทัวร์</TableCell>
                                <TableCell sx={{ whiteSpace: "nowrap" }}>จำนวนลูกค้าเด็ก</TableCell>
                                <TableCell sx={{ whiteSpace: "nowrap" }}>ทะเบียนรถ</TableCell>
                                <TableCell sx={{ whiteSpace: "nowrap" }}>ประเภทรถ</TableCell>
                                <TableCell sx={{ whiteSpace: "nowrap" }}>ชื่อประเภทรถ</TableCell>
                                <TableCell sx={{ whiteSpace: "nowrap" }}>เบอร์โทรศัพท์ติดต่อ</TableCell>
                                <TableCell sx={{ whiteSpace: "nowrap" }}></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                _.orderBy(rows, ["datebook", "timebook"], ["asc", "asc"]).map((row, index) => {
                                    return (
                                        <TableRow key={`row-${index}`} sx={{ backgroundColor: row.status !== "confirm" ? "white" : "whitesmoke" }}>
                                            <TableCell sx={{ whiteSpace: "nowrap" }} padding="checkbox">
                                                <Checkbox
                                                    checked={isSelected(row.id)}
                                                    onChange={(event) => handleCheck(row)}
                                                />
                                            </TableCell>
                                            <TableCell sx={{ whiteSpace: "nowrap" }}>{fDateTo(row.datebook, "dd/MM/yyyy")}</TableCell>
                                            <TableCell sx={{ whiteSpace: "nowrap" }}>{row.timebook}</TableCell>
                                            <TableCell sx={{ whiteSpace: "nowrap" }}>{row.thaiguidecode}</TableCell>
                                            <TableCell sx={{ whiteSpace: "nowrap" }}>{row.thaifullname}</TableCell>
                                            <TableCell sx={{ whiteSpace: "nowrap" }}>{row.v_c_fullname}</TableCell>
                                            <TableCell sx={{ whiteSpace: "nowrap" }}>{row.tourcompany}</TableCell>
                                            <TableCell sx={{ whiteSpace: "nowrap" }}>{row.groupcode}</TableCell>
                                            <TableCell sx={{ whiteSpace: "nowrap" }}>{row.nationality}</TableCell>
                                            {/* <TableCell sx={{ whiteSpace: "nowrap" }}>{`${row.adults},${row.tourleaders},${row.child}`}</TableCell> */}
                                            <TableCell sx={{ whiteSpace: "nowrap" }}>{row.adults}</TableCell>
                                            <TableCell sx={{ whiteSpace: "nowrap" }}>{row.tourleaders}</TableCell>
                                            <TableCell sx={{ whiteSpace: "nowrap" }}>{row.child}</TableCell>
                                            <TableCell sx={{ whiteSpace: "nowrap" }}>{row.licenseplate}</TableCell>
                                            <TableCell sx={{ whiteSpace: "nowrap" }}>{row.vehicletype}</TableCell>
                                            <TableCell sx={{ whiteSpace: "nowrap" }}>{row.vehicletypename}</TableCell>
                                            <TableCell sx={{ whiteSpace: "nowrap" }}>{row.contactnumber}</TableCell>
                                            <TableCell>
                                                <Box>
                                                    <Stack direction="row" spacing={1} justifyContent="end">
                                                        {row.file_id !== "" ? row.file_id !== "deleted" ? <Button href={`${baseURL}/view/${row.file_id}`} target="_blank" variant="outlined" size="small" >ดูไฟล์</Button> : "ลบไฟล์แล้ว" : ""}
                                                        {row.status !== "confirm" ? <Button variant="contained" color="success" onClick={() => { onClickConfirm({ id: row.id, status: "confirm" }) }} size="small">ยืนยัน</Button> : <Button variant="outlined" color="error" onClick={() => { handleSnackWithAction({ message: "ต้องการยกเลิก?", onHandle: () => onClickConfirm({ id: row.id, status: "" }) }) }} size="small">ยกเลิก</Button>}
                                                        {(contype === "00" || contype === "20") && (<Button variant='contained' color="error" onClick={() => { handleSnackWithAction({ message: "ต้องการลบรายการ?", onHandle: () => onDelete({ id: row.id, filename: row.file_id }) }) }} size="small">ลบ</Button>)}
                                                    </Stack>
                                                </Box>
                                            </TableCell>
                                        </TableRow>
                                    )
                                })
                            }
                        </TableBody>
                    </Table>
                </Box>
                {/* </Stack> */}
            </Box >
        </Container >
    )
}
